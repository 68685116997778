<template>
  <content-header title="Users">
  </content-header>
  <content>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">

            <button class="btn btn-primary btn-xs mb-2" @click="openModalAddUser()">Add User</button>

            <EasyDataTable :items="users.items" :headers="users.headers" show-index table-class-name="customize-table">
              <template #item-alamat="item">
                <span>{{ item.alamat }}</span>
              </template>

              <template #item-status="item">
                <!-- <span v-if="item.amil && item.admin" class="badge badge-fuchsia">Amil / Admin</span>
            <span v-else-if="item.amil" class="badge badge-success">Amil</span>
            <span v-else-if="item.admin" class="badge badge-danger">Admin</span> -->
                <div>
                  <div class="badge badge-success mr-1" v-if="item.admin == true">Admin</div>
                  <div class="badge badge-danger mr-1" v-else>Admin</div>
                </div>

                <div>
                  <div class="badge badge-success mr-1" v-if="item.amil == true">Amil</div>
                  <div class="badge badge-danger mr-1" v-else>Amil</div>
                </div>


              </template>

              <template #item-aktif="item">
                <span class="badge badge-primary mr-1" v-if="item.active == true">Aktif</span>
                <span class="badge badge-danger mr-1" v-else>Non Aktif</span>
              </template>

              <template #item-option="item">
                <button class="btn badge badge-warning" type="button" @click="openModalUpdateUser(item)">Detail</button>
              </template>

            </EasyDataTable>
          </div>
        </div>

      </div>
    </div>
  </content>

  <modal v-if="modal.add_user" @hide-modal="hideModal()">
    <template v-slot:header >Formulir {{ modal.data_modal.category }}</template>
    <template v-slot:content>
      <add-user @hide-modal="fetchUsers()" :data_modal="modal.data_modal"></add-user>
    </template>
  </modal>
</template>

<script>

import Modal from "@/components/Modal.vue";
import AddUser from "@/components/AddUser.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "users",
  components: {
    Modal, AddUser
  },
  
  data() {
    return {
      url: '/api',
      users: {
        search: '',
        items: [],
        headers: [
          {
            text: 'Nama',
            value: 'nama',
          },
          {
            text: 'Alamat',
            value: 'alamat',
          },
          {
            text: 'Telepon',
            value: 'telepon',
          },
          {
            text: 'Status',
            value: 'status',
          },
          {
            text: 'Aktif',
            value: 'aktif',
          },
          {
            text: 'Option',
            value: 'option',
          },
        ]
      },
      modal:{
        add_user: false,
        data_modal: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods: {
    init() {
      this.fetchUsers()
    },

    hideModal() {
      this.modal.add_user = false
    },

    fetchUsers() {
      this.hideModal()
      axios.get(`${this.url}/list-user`, { params: { nick: this.user.username } })
        .then((a) => {
          console.log(a.data);
          
          if (a.data.code == 200) {
            this.users.items = a.data.response
          }
          // console.log(a.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {

        })
    },

    openModalAddUser() {
      let data_modal = {
        category: "add_user"
      }
      this.modal.data_modal = data_modal
      this.modal.add_user = true

    },

    openModalUpdateUser(item) {
      let data_modal = {
        category: "update_user",
        data: item
      }
      // console.log("Open Modal", item);
      
      this.modal.data_modal = data_modal
      // console.log(this.modal.data_modal.category);
      // console.log(this.modal.data_modal);
      
      this.modal.add_user = true
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style>
.customize-table {
  /* Table Header */
  --easy-table-header-item-padding: 10px 15px;

  /* Table Body */
  --easy-table-body-item-padding: 10px 15px;
}
</style>