<template>
  <content-header title="Donasi Cash"></content-header>
  <content>
    List Donasi Cash
  </content>

</template>

<script>

export default{
 
}
</script>

<style>

</style>