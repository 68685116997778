import axios from 'axios'
import router from '@/router'

const auth = {
  namespaced: true,
  state:{
    user:{
      username: null,
      fullname: null,
    },
    token: null,
    login: false
  },

  getters: {
    user(state){
      return state.user
    },

    token(state){
      return state.token
    },

    isLogin(state){
      return state.login
    }
  },

  mutations:{
    login(state, payload){
      state.user.username = payload.username
      state.user.fullname = payload.fullname
      state.token = payload.token
      state.login = true
    },
    logout(state){
      state.user.username = null
      state.user.fullname = null
      state.token = null
      state.login = false
      router.push('/login')
    }
  },

  actions:{
    async login({dispatch}, payload) {
      console.log("paramsfrom login", payload);
      
      return new Promise((resolve, reject) => {
        axios.post('/login', payload)
        .then((a)=>{
          console.log(a.data.response.nama);
          
					if (a.data.code == 200) {
            let p = {
              code: 200,
              username: a.data.response.username,
              fullname: a.data.response.nama,
              token: a.data.response.token
            }
						dispatch('attempt', p)
						resolve(p) 
					}else{
            // console.log(a.data); 
            
            resolve(a.data)
          }
				})
				.catch((e)=>{
					console.log("login error: ", e);
					reject(e)
				})
      })
    },

    async attempt({commit},payload){
			
      if (payload.code == 200) {
        commit('login', payload)
      }else{
        router.push('/login')	
      }
    
  },
  logout({commit}){
    commit('logout')
  }
  }
}

export default auth