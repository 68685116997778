import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import MainLayout from '@/layouts/MainLayout.vue'
import DashboardView from '@/views/dashboard/DashboardView.vue'
import Users from '@/views/users/UsersView.vue'
import DonasiCash from '@/views/donasi/DonasiCash.vue'
import Pengaturan from '@/views/Pengaturan.vue'

import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
      },
      {
        path: '/users',
        name: 'users',
        component: Users
      },
      {
        path: '/donasi-cash',
        name: 'donasi-cash',
        component: DonasiCash
      },
      {
        path: '/pengaturan',
        name: 'pengaturan',
        component: Pengaturan
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    if (store.getters['auth/isLogin'] != true) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
