<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-6">

      <div class="form-group">
        <label for="namalengkap">Nama Lengkap</label>
        <input type="text" autocomplete="off" class="form-control" id="namalengkap" v-model="form_data.namalengkap">
      </div>

      <div class="form-group" v-if="data_modal['category'] == 'add_user'">
        <label for="username">Username</label>
        <input type="text" autocomplete="off" class="form-control" id="username" v-model="form_data.username">
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" v-model="form_data.email">
      </div>

      <div class="form-group" v-if="data_modal['category'] == 'add_user'">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" v-model="form_data.password">
      </div>



      <div class="form-group">
        <label for="telepon">Telepon</label>
        <input type="text" autocomplete="off" class="form-control" id="telepon" v-model="form_data.telepon">
      </div>


      <div class="form-group">
        <label for="nik">NIK</label>
        <input type="text" autocomplete="off" class="form-control" id="nik" v-model="form_data.nik">
      </div>

      <div class="form-group">
        <label for="alamat">Alamat</label>
        <input type="text" autocomplete="off" class="form-control" id="alamat" v-model="form_data.alamat">
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="rt">RT</label>
            <input type="text" autocomplete="off" class="form-control" id="rt" v-model="form_data.rt">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="rw">RW</label>
            <input type="text" autocomplete="off" class="form-control" id="rw" v-model="form_data.rw">
          </div>
        </div>
      </div>
      <!-- </div>  --> 

      <!-- <div v-if="data_modal['category'] == 'add_user'"> -->
      <div class="form-group">
        <label for="provinsi">Provinsi</label>
        <input type="search" autocomplete="off" class="form-control" id="provinsi" v-model="provinsi.search_provinsi"
          @input="provinsiData()">
        <ul class="list-group" v-if="provinsi.items.length > 0">
          <li v-for="item in provinsi.items" :key="item.id" class="list-group-item" @click="selectProvinsi(item)">
            {{ item.provinsi }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kabupaten">Kabupaten / Kota</label>
        <input type="search" autocomplete="off" class="form-control" id="kabupaten" v-model="kabupaten.search_kabupaten"
          @input="kabupatenData()" :disabled="kabupaten.isDisabled">
        <ul class="list-group" v-if="kabupaten.items.length > 0">
          <li v-for="item in kabupaten.items" :key="item.id" class="list-group-item" @click="selectKabupaten(item)">
            {{ item.kabupaten }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kecamatan">Kecamatan</label>
        <input type="search" autocomplete="off" class="form-control" id="kecamatan" v-model="kecamatan.search_kecamatan"
          @input="kecamatanData()" :disabled="kecamatan.isDisabled">
        <ul class="list-group" v-if="kecamatan.items.length > 0">
          <li v-for="item in kecamatan.items" :key="item.id" class="list-group-item" @click="selectKecamatan(item)">
            {{ item.kecamatan }}</li>
        </ul>
      </div>

      <div class="form-group">
        <label for="kelurahan">Kelurahan</label>
        <input type="search" autocomplete="off" class="form-control" id="kelurahan" v-model="kelurahan.search_kelurahan"
          @input="kelurahanData()" :disabled="kelurahan.isDisabled">
        <ul class="list-group" v-if="kelurahan.items.length > 0">
          <li v-for="item in kelurahan.items" :key="item.id" class="list-group-item" @click="selectKelurahan(item)">
            {{ item.kelurahan }}</li>
        </ul>
      </div>

      <!-- <div class="form-group">
        <label for="jalan">Dusun / Jalan</label>
        <input type="text" autocomplete="off" class="form-control" id="jalan" v-model="form_data.jalan">
      </div>  -->





      <div v-if="data_modal.category == 'update_user'">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-md-4">Status User</div>
              <div class="col-md-8">
                <div class="row">
                  <small v-if="updateStatus.adminpending.status" class="text text-">{{
                    this.updateStatus.adminpending.message }}</small>

                  <small v-if="updateStatus.amilpending.status" class="text text-">{{
                    this.updateStatus.amilpending.message }}</small>


                  <div class="col-md-3">
                    <div class="d-flex d-inline-flex">
                      <div>
                        <span class="badge badge-success" v-if="data_modal.data.admin == true">Admin</span>
                        <span class="badge badge-danger" v-else>Admin</span>
                      </div>

                      <div>
                        <!-- <span class="badge badge-primary ml-1 pointer" v-if="data_modal.data.admin == false"
                          @click="updateStatusAdmin(true)">Jadikan
                          Admin</span>
                        <span class="badge badge-primary ml-1 pointer" v-else @click="updateStatusAdmin(false)">Jadikan
                          User biasa</span> -->
                          <div class="form-check ml-2">
                            <input type="checkbox" class="form-check-input" id="admin" v-model="form_data.admin" ref="checkbox_admin">
                          </div>
                      </div>
                    </div>

                    <div class="d-flex d-inline-flex">
                      <div>
                        <span class="badge badge-success" v-if="data_modal.data.amil == true">Amil</span>
                        <span class="badge badge-danger" v-else>Amil</span>
                      </div>

                      <div>
                          <div class="form-check ml-2">
                            <input type="checkbox" class="form-check-input" id="amil" v-model="form_data.amil" ref="checkbox_amil">
                          </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-md-4">Status Aktif</div>
              <div class="col-md-8">
                <div class="row">
                  <small v-if="updateStatus.activepending.status" class="text text-">{{
                    this.updateStatus.activepending.message }}</small>
                  <div class="col-md-3">
                    <input type="checkbox" class="form-check-input" id="admin" v-model="form_data.active" ref="checkbox_admin">
                    <!-- <div class="d-flex d-inline-flex"> -->
                     <!-- <div>

                        <span class="badge badge-success" v-if="data_modal.data.active == true">True</span>
                        <span class="badge badge-danger" v-else>false</span>
                      </div> -->

                      <!-- <div> -->
                          <!-- <div class="form-check ml-2"> -->
                            <!-- <input type="checkbox" class="form-check-input" id="admin" v-model="form_data.active" ref="checkbox_admin"> -->
                          <!-- </div> -->
                      <!-- </div> -->
                    <!-- </div> -->
                    <!-- <span class="badge badge-primary pointer" v-if="data_modal.data.active == false"
                      @click="updateStatusActive(true)">Aktifkan</span>
                    <span class="badge badge-primary pointer" v-else @click="updateStatusActive(false)">Non
                      Aktifkan</span> -->
                      
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="isKtpExists == false">
            <label for="upload_ktp">Upload KTP</label>
            <input type="file" class="form-control" id="upload_ktp" @change="onFileChange">
          </div>


          <!-- <div v-else> -->
          <div class="row" v-else>
            <div class="col-lg-12">
              <label for="upload_ktp">Data KTP</label>
              <br>
              <img :src="image_file" alt="" height="200px">
              <br>

              <button class="btn btn-sm btn-primary" @click="updateKtp()">Update KTP</button>
              <!-- <button class="btn btn-sm btn-primary float-right" @click="updateKtp()">Update KTP</button> -->
            </div>
          </div>
          <!-- </div> -->


        </div>
      </div>

      <button class="btn btn-sm btn-primary float-right" @click="addUser()"
        v-if="data_modal.category == 'add_user'">Simpan</button>
      <button class="btn btn-sm btn-primary float-right" @click="updateUser()"
        v-if="data_modal.category == 'update_user'">Update</button>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ['data_modal'],
  name: 'AddUser',
  data() {
    return {
      url: '/api',
      file: null,
      image_file: null,
      isKtpExists: false,
      form_data: {
        namalengkap: "",
        username: "",
        password: "",
        nik: "",
        alamat: "",
        provinsi: "",
        provinsi_id: "",
        kabupaten: "",
        kabupaten_id: "",
        kecamatan: "",
        kecamatan_id: "",
        kelurahan: "",
        kelurahan_id: "",
        email: "",
        telepon: "",
        admin: false,
        amil: false,
        active: false,
        rt: "",
        rw: "",
        jalan: "",
        nama: "",
        file_ktp: ""
      },
      provinsi: {
        items: [],
        search_provinsi: "",
        selected_id: "",
        selected_provinsi: ""
      },
      kabupaten: {
        isDisabled: true,
        items: [],
        search_kabupaten: "",
        selected_id: "",
        selected_kabupaten: ""
      },
      kecamatan: {
        isDisabled: true,
        items: [],
        search_kecamatan: "",
        selected_id: "",
        selected_kecamatan: ""
      },
      kelurahan: {
        isDisabled: true,
        items: [],
        search_kelurahan: "",
        selected_id: "",
        selected_kelurahan: ""
      },
      updateStatus: {
        adminpending: {
          status: false,
          message: ''
        },
        amilpending: {
          status: false,
          message: ''
        },
        activepending: {
          status: false,
          message: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      "user": "auth/user"
    })
  },

  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.file = files[0]
    },

    resetProvinsi() {
      this.kabupaten.selected_kabupaten = ""
      this.kabupaten.search_kabupaten = ""
      this.kabupaten.selected_id = ""
      // this.kabupaten.isDisabled = true

      this.kecamatan.selected_kecamatan = ""
      this.kecamatan.search_kecamatan = ""
      this.kecamatan.selected_id = ""
      // this.kecamatan.isDisabled = true

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    resetKabupaten() {
     
      // this.kabupaten.isDisabled = true

      this.kecamatan.selected_kecamatan = ""
      this.kecamatan.search_kecamatan = ""
      this.kecamatan.selected_id = ""
      // this.kecamatan.isDisabled = true

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    resetKecamatan() {

      this.kelurahan.selected_kelurahan = ""
      this.kelurahan.search_kelurahan = ""
      this.kelurahan.selected_id = ""
    },

    // resetKelurahan() {
    //   this.form_data.jalan = ""
    //   this.form_data.rt = ""
    //   this.form_data.rw = ""
    //   this.form_data.alamat = ""
    // },

    provinsiData() {
      let search = this.provinsi.search_provinsi
      if (search.length == 0) {
        this.resetProvinsi()
      }

      if (search.length > 3) {
        axios.get(`${this.url}/provinsi`)
          .then((a) => {
            if (a.data.code == 200) {
              let data = a.data.response
              // console.log(data);
              const temp_provinsi = data.filter(item => item.provinsi.toLowerCase().includes(search.toLowerCase()))
              // console.log(temp_provinsi);
              this.provinsi.items = temp_provinsi

            }

          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.provinsi.items = []
        this.provinsi.selected_id = ""
        this.provinsi.selected_provinsi = ""
        
      }


    },

    selectProvinsi(item) {
      // this.provinsi.selected = item
      if (this.provinsi.selected_provinsi != "" || this.provinsi.selected_provinsi != null && this.provinsi.selected_provinsi != item.provinsi) {
        console.log("harusnya masuk sini karena ganti provinsi");

        this.kabupaten.selected_kabupaten = ""
        this.kabupaten.search_kabupaten = ""
        this.kabupaten.selected_id = ""
        // this.kabupaten.isDisabled = true

        this.kecamatan.selected_kecamatan = ""
        this.kecamatan.search_kecamatan = ""
        this.kecamatan.selected_id = ""
        // this.kecamatan.isDisabled = true

        this.kelurahan.selected_kelurahan = ""
        this.kelurahan.search_kelurahan = ""
        this.kelurahan.selected_id = ""
        // this.kelurahan.isDisabled = true

        this.provinsi.items = []
        this.provinsi.selected_id = item.id
        this.provinsi.search_provinsi = item.provinsi
        this.provinsi.selected_provinsi = item.provinsi

        // this.form_data.jalan = ""
        // this.form_data.rt = ""
        // this.form_data.rw = ""
        // this.form_data.alamat = ""

        if (this.provinsi.selected_id != "") {
          this.kabupatenData()
        }
      } else {
        console.log("tidak ganti provinsi");

        this.provinsi.items = []
        this.provinsi.selected_id = item.id
        this.provinsi.search_provinsi = item.provinsi
        this.provinsi.selected_provinsi = item.provinsi
        if (this.provinsi.selected_id != "") {
          this.kabupatenData()
        }
      }

      // if (this.provinsi.selected_id != "") {
      //   this.kabupatenData()
      // }
      // console.log(item);

    },

    kabupatenData() {
      this.kabupaten.isDisabled = false
      if (this.kabupaten.search_kabupaten.length == 0) {
        this.resetKabupaten()
        
      }

      if (this.kabupaten.search_kabupaten.length > 3) {
        axios.get(`${this.url}/kabupaten`, {
          params: {
            provinsi: this.provinsi.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kabupaten = a.data.response
            let temp_kabupaten = list_kabupaten.filter(item => item.kabupaten.toLowerCase().includes(this.kabupaten.search_kabupaten.toLowerCase()))
            this.kabupaten.items = temp_kabupaten
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kabupaten.items = []
        this.kabupaten.selected_id = ""
        // this.kabupaten.search_kabupaten = ""
      }
    },

    selectKabupaten(item) {
      this.kabupaten.items = []
      this.kabupaten.selected_id = item.id
      this.kabupaten.search_kabupaten = item.kabupaten
      this.kabupaten.selected_kabupaten = item.kabupaten
      if (this.kabupaten.selected_id != "") {
        this.kecamatanData()
      }
    },

    kecamatanData() {
      this.kecamatan.isDisabled = false
      if (this.kecamatan.search_kecamatan.length == 0) {
        this.resetKecamatan()
        
      }
      if (this.kecamatan.search_kecamatan.length > 3) {
        axios.get(`${this.url}/kecamatan`, {
          params: {
            kabupaten: this.kabupaten.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kecamatan = a.data.response
            let temp_kecamatan = list_kecamatan.filter(item => item.kecamatan.toLowerCase().includes(this.kecamatan.search_kecamatan.toLowerCase()))
            this.kecamatan.items = temp_kecamatan
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kecamatan.items = []
        this.kecamatan.selected_id = ""
        // this.kecamatan.search_kecamatan = ""
      }
    },

    selectKecamatan(item) {
      this.kecamatan.items = []
      this.kecamatan.selected_id = item.id
      this.kecamatan.search_kecamatan = item.kecamatan
      this.kecamatan.selected_kecamatan = item.kecamatan
      if (this.kecamatan.selected_id != "") {
        this.kelurahanData()
      }
    },

    kelurahanData() {
      this.kelurahan.isDisabled = false
      // if (this.kelurahan.search_kelurahan.length == 0) {
      //   this.resetKelurahan()
        
      // }
      if (this.kelurahan.search_kelurahan.length > 3) {
        axios.get(`${this.url}/kelurahan`, {
          params: {
            kecamatan: this.kecamatan.selected_id
          }
        }).then((a) => {
          if (a.data.code == 200) {
            let list_kelurahan = a.data.response
            let temp_kelurahan = list_kelurahan.filter(item => item.kelurahan.toLowerCase().includes(this.kelurahan.search_kelurahan.toLowerCase()))
            this.kelurahan.items = temp_kelurahan
          }
        })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {

          })
      } else {
        this.kelurahan.items = []
        this.kelurahan.selected_id = ""
        // this.kelurahan.search_kelurahan = ""
      }
    },

    selectKelurahan(item) {
      this.kelurahan.items = []
      this.kelurahan.selected_id = item.id
      this.kelurahan.search_kelurahan = item.kelurahan
      this.kelurahan.selected_kelurahan = item.kelurahan
      this.form_data.kelurahan_id = item.id


    },

    setAlamat() {
      let text = `${this.form_data.jalan}, Kelurahan: ${this.kelurahan.selected_kelurahan} RT: ${this.form_data.rt} RW: ${this.form_data.rw}, Kecamatan: ${this.kecamatan.selected_kecamatan}, ${this.kabupaten.selected_kabupaten}, ${this.provinsi.selected_provinsi}`
      this.form_data.alamat = text


      this.form_data.kelurahan_id = `${this.kelurahan.selected_id}`
    },

    async addUser() {
      if (this.form_data.kelurahan_id == "") {
        alert("Data kelurahan tidak sesuai")
        return
      }
      let params = {
        nama: this.form_data.namalengkap,
        nickname: this.user.username,
        username: this.form_data.username,
        password: this.form_data.password,
        nik: this.form_data.nik,
        alamat: this.form_data.alamat,
        kelurahan: this.form_data.kelurahan_id,
        email: this.form_data.email,
        telepon: this.form_data.telepon,
        admin: this.form_data.admin,
        amil: this.form_data.amil,
        file_ktp: this.form_data.file_ktp,
        rt: this.form_data.rt,
        rw: this.form_data.rw,
      }

      console.log(params);

      axios.post(`${this.url}/add-user`, params)
        .then((a) => {
          if (a.data.code == 200) {
            alert(a.data.response)
          } else {
            alert(a.data.message)
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.$emit('hide-modal')
        })
    },

    async updateUser() {

      // if (this.file == null && this.form_data.file_ktp == "") {
      //   alert("Pilih file terlebih dahulu");
      //   return;
      // }

      // console.log(this.file);


      const formData = new FormData();
      if (this.file != null) {
        formData.append('file', this.file);
      }
      // formData.append('file', this.file == "" || this.file == null ? "" : this.file);
      formData.append('nama', this.form_data.namalengkap)
      formData.append('nickname', this.user.username)
      formData.append('username', this.form_data.username)
      formData.append('nik', this.form_data.nik)
      formData.append('alamat', this.form_data.alamat)
      formData.append('kelurahan', this.form_data.kelurahan_id)
      formData.append('email', this.form_data.email)
      formData.append('telepon', this.form_data.telepon)
      formData.append('admin', this.form_data.admin)
      formData.append('amil', this.form_data.amil)
      formData.append('file_ktp', this.form_data.file_ktp)
      formData.append('active', this.form_data.active)
      formData.append('rt', this.form_data.rt)
      formData.append('rw', this.form_data.rw)

      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }

      axios.put(`${this.url}/edit-user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((a) => {
          console.log(a.data);
          if (a.data.code == 200) {
            alert(a.data.response.message)
            return
          } else {
            alert(a.data.response.message)
            return
          }

        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.$emit('hide-modal')
        })
    },

    updateStatusAdmin(isAdmin) {
      console.log(this.form_data);

      if (isAdmin == true) {
        let message = 'Status akan berubah menjadi Admin saat proses update selesai'
        // console.log("true");
        this.updateStatus.adminpending.status = true
        this.updateStatus.adminpending.message = message
        this.form_data.admin = true
      } else {
        let message = 'Status Admin akan berubah menjadi user biasa saat proses update selesai'
        this.updateStatus.adminpending.status = true
        this.updateStatus.adminpending.message = message
        this.form_data.admin = false
        // console.log("false");

      }
      console.log("Status Admin: ", this.form_data);

    },

    updateStatusAmil(isAmil) {
      if (isAmil == true) {
        let message = 'Status akan berubah menjadi Pengurus Amil saat proses update selesai'
        this.updateStatus.amilpending.status = true
        this.updateStatus.amilpending.message = message
        this.form_data.amil = true
      } else {
        let message = 'Status Amil akan berubah menjadi user biasa saat proses update selesai'
        this.updateStatus.amilpending.status = true
        this.updateStatus.amilpending.message = message
        this.form_data.amil = false
      }
      console.log("Status Amil: ", this.form_data);

    },

    updateStatusActive(status) {
      if (status == true) {
        let message = 'Status akan berubah menjadi Aktif saat proses update selesai'
        this.updateStatus.activepending.status = true
        this.updateStatus.activepending.message = message
        this.form_data.active = true
      } else {
        let message = 'Status akan berubah menjadi Tidak Aktif saat proses update selesai'
        this.updateStatus.activepending.status = true
        this.updateStatus.activepending.message = message
        this.form_data.active = false
      }
      console.log("Status Aktif: ", this.form_data);

    },

    updateKtp() {
      this.isKtpExists = false
    },

    async userDetail(nick, user) {
      let params = {
        nick: nick,
        username: user
      }
      let data = null
      await axios.get(`${this.url}/detail-user`, { params })
        .then((a) => {
          // console.log("detail user", a.data);
          if (a.data.code == 200) {
            data = a.data
            // return
          } else {
            data = a.data.message
            // return
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {

        })

      return data
    },

    async setupEditUser() {
      if (this.data_modal.category == "update_user") {

        let user = await this.userDetail(this.user.username, this.data_modal.data.username)
          .then((a) => {
            if (a.code == 200) {
              return a.response
            } else {
              return null
            }

          })
          .catch((e) => {
            console.log(e);
          })

        console.log("User Detail", user);


        if (user != null) {
          this.form_data.namalengkap = user.nama
          this.form_data.username = this.data_modal.data.username
          this.form_data.nik = user.nik

          this.form_data.provinsi = user.provinsi
          this.form_data.provinsi_id = user.provinsi_id

          this.provinsi.search_provinsi = user.provinsi
          this.provinsi.selected_id = user.provinsi_id
          this.provinsi.selected_provinsi = user.provinsi

          this.kabupaten.search_kabupaten = user.kabupaten
          this.kabupaten.selected_id = user.kabupaten_id
          this.kabupaten.selected_kabupaten = user.kabupaten
          this.form_data.kabupaten_id = user.kabupaten_id
          this.form_data.kabupaten = user.kabupaten

          this.kecamatan.search_kecamatan = user.kecamatan
          this.kecamatan.selected_id = user.kecamatan_id
          this.kecamatan.selected_kecamatan = user.kecamatan
          this.form_data.kecamatan_id = user.kecamatan_id
          this.form_data.kecamatan = user.kecamatan

          this.kelurahan.search_kelurahan = user.kelurahan
          this.kelurahan.selected_id = user.kelurahan_id
          this.kelurahan.selected_kelurahan = user.kelurahan
          this.form_data.kelurahan_id = user.kelurahan_id
          this.form_data.kelurahan = user.kelurahan

          this.form_data.email = user.email
          this.form_data.alamat = user.alamat
          this.form_data.telepon = user.telepon
          this.form_data.admin = user.admin
          this.form_data.amil = user.amil
          this.form_data.active = user.active
          this.form_data.file_ktp = user.ktp_file

          this.form_data.rt = user.rt == null || user.rt == "" ? "" : user.rt.trim()
          this.form_data.rw = user.rw == null || user.rw == "" ? "" : user.rw.trim()
          // this.form_data.jalan = dusun

          if (this.data_modal.data.ktp_file != null || this.data_modal.data.ktp_file != "") {
            this.isKtpExists = true
          }
          if (this.data_modal.data.file_ktp != null || this.data_modal.data.file_ktp != "") {
            this.image_file = `http://192.168.100.112:8001/uploads/${this.data_modal.data.ktp_file}`
          }

        }



      }
    }
  },

  async mounted() {
    this.setupEditUser()
    // console.log(this.data_modal);

    // console.log(this.category);

  }

}
</script>

<style></style>